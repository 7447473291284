import {onFind} from "@elements/init-modules-in-scope";
import {findIn, addClass, removeClass, hasClass, on} from '@elements/dom-utils';

const selectors = {
    btn: '.js-autoplay-video__btn',
    video: '.js-autoplay-video__video',
    icon: '.js-autoplay-video__icon',
    base: '.js-autoplay-video'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let playButton = findIn(selectors.btn, baseElement);
        let video = findIn(selectors.video, baseElement);
        let icon = findIn(selectors.icon, baseElement);

        on('click', function () {
            let isPlaying = hasClass('is-playing', playButton);
            if(isPlaying){
                video.pause();
                removeClass('is-playing', playButton);
                removeClass('icon-pause', icon);
                addClass('icon-play', icon);
            } else {
                video.play();
                addClass('is-playing', playButton);
                removeClass('icon-play', icon);
                addClass('icon-pause', icon);
            }
        }, playButton);
    });
}
