import {add} from '@elements/scroll-animations';
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn,on} from '@elements/dom-utils';

export function init() {
    onFind('.js-parallax', function (baseElement) {
        let parallaxElements = [];
        parallaxElements.push(baseElement);

        let continueParallax = baseElement.getAttribute('data-parallax-continue') || false;
        let fadeInParallax = baseElement.getAttribute('data-parallax-fade-in') || false;
        let heroParallax = baseElement.getAttribute('data-parallax-hero') || false;
        let imgParallax = baseElement.getAttribute('data-parallax-img') || false;
        let heroBgParallax = baseElement.getAttribute('data-parallax-hero-bg') || false;

        let parallaxXValue = baseElement.getAttribute('data-parallax-x-value') || 0;
        let parallaxYValue = baseElement.getAttribute('data-parallax-y-value') || 0;

        let parallaxYValueMobile = baseElement.getAttribute('data-parallax-y-value-mobile') || false;
        let parallaxXValueMobile = baseElement.getAttribute('data-parallax-x-value-mobile') || false;

        if(parallaxYValueMobile && isMobile()) {
            parallaxYValue = parallaxYValueMobile;
        }
        if(parallaxXValueMobile && isMobile()) {
            parallaxXValue = parallaxXValueMobile;
        }

        let fixedXValue = baseElement.getAttribute('data-parallax-fixed-x-value') || false;

        add(
            parallaxElements,
            calculateAnimationProgress({continueParallax, fadeInParallax, heroParallax, imgParallax, heroBgParallax}),
            setAnimationProgress({imgParallax, parallaxXValue, parallaxYValue, fixedXValue, heroBgParallax})
        );


    });
}

const isMobile = () => matchMedia('(max-width: 767px)').matches;

// gets element -> returns number between 0 and 1
function calculateAnimationProgress({continueParallax, fadeInParallax, heroParallax, imgParallax, heroBgParallax}) {
    return function(element) {
        let start = window.innerHeight - 100;
        let end = 300;

        if(continueParallax) {
            end = (element.offsetHeight - 100) * (-1);
        } else if (fadeInParallax) {
            // if animation should only be on bottom of page
            end = window.innerHeight / 1.4;
        } else if(heroParallax || heroBgParallax) {
            start = window.innerHeight - 100;
            // animation for hero
            end = 0;
        } else if (imgParallax) {
            end = -400;
        }

        let progress;

        if(heroParallax || heroBgParallax) {
            progress =  Math.max(
                Math.min(
                    (element.getBoundingClientRect().top - end) / (end - start),
                    1
                ),
                0
            );
        } else {
            progress =  Math.max(
                Math.min(
                    (element.getBoundingClientRect().top - end) / (start - end),
                    1
                ),
                0
            );
        }
        return progress;
    };
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress({imgParallax, parallaxXValue, parallaxYValue, fixedXValue, heroBgParallax}) {
    return function(element, progress) {

        let parallaxMultiplicator = isMobile() ? 0.3 : 0.9;
        let parallaxXProgress,
            parallaxYProgress;

        if(imgParallax) {
            parallaxYValue = isMobile() ? -100 : -200;
        } else {
            parallaxXProgress = (progress * (parallaxXValue * parallaxMultiplicator)) + "px";
            parallaxYProgress = (progress * (parallaxYValue * parallaxMultiplicator)) + "px";
        }

        if(fixedXValue) {
            parallaxXProgress = fixedXValue;
        }

        if(!heroBgParallax) {
            element.style.transform = "translate3d(" + parallaxXProgress + ", "+ parallaxYProgress +", 0)";
        } else {
            element.style.backgroundPositionY = parallaxYProgress;
        }
    }
}