import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {removeClass,on,addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-img-teaser-slider',
    items: '.js-img-teaser-slider__item'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let onSlide = false;
        let blazeItems = findAllIn(selectors.items, baseElement);
        let allSlides = findAllIn('.js-img-teaser-slider__item', baseElement);
        addClass('is-active', allSlides[1]);
        addClass('is-inViewport', allSlides[0]);
        addClass('is-inViewport', allSlides[1]);
        addClass('is-inViewport', allSlides[2]);
        const blazeSlider = new BlazeSlider(baseElement, {
            all: {
                slidesToScroll: 1,
                slidesToShow: 3,
                slideGap: 'calc(60rem/16)',
                draggable: true,
                transitionDuration: 250,
                transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                loop: false
            },
            '(max-width: 768px)': {
                slidesToShow: 2,
                slideGap: 'calc(12rem/16)',
            },
        });

        const unsubscribe = blazeSlider.onSlide(
            (pageIndex, firstVisibleSlideIndex, lastVisibleSlideIndex) => {
                let allSlides = findAllIn('.js-img-teaser-slider__item', baseElement);
                allSlides.forEach(function (slide) {
                    removeClass('is-inViewport', slide);
                });
                removeClass('is-active', allSlides[firstVisibleSlideIndex]);
                removeClass('is-active', allSlides[lastVisibleSlideIndex]);
                addClass('is-active', allSlides[pageIndex+1]);
                addClass('is-inViewport', allSlides[pageIndex]);
                addClass('is-inViewport', allSlides[pageIndex+1]);
                addClass('is-inViewport', allSlides[pageIndex+2]);
            });
    });
}

