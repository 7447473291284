import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from '@elements/viewport-utils';
import {addClass, removeClass, findIn} from '@elements/dom-utils';

let settings = {
    offset: -100
};

export function init() {
    onFind('.js-circle-animation', function (baseElement) {
        onEnterViewPort (baseElement, function(element){
            addClass('animate', baseElement);
        }, settings);
    });
}
