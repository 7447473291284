"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {removeClass,on,addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-img-slider',
    items: '.js-img-slider__item'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let onSlide = false;
        let blazeItems = findAllIn(selectors.items, baseElement);
        const blazeSlider = new BlazeSlider(baseElement, {
            all: {
                slidesToScroll: 1,
                slidesToShow: 1,
                slideGap: '0px',
                draggable: true,
                transitionDuration: 250,
                transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                loop: true
            },
        });
    });
}

