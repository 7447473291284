"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {removeClass,on,addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-img-content-slider',
    items: '.js-img-content-slider__item'
};
const isMobile = matchMedia('(max-width: 767px)').matches || false;
export function init() {
    onFind(selectors.base, function (baseElement) {
        let onSlide = false;
        let blazeItems = findAllIn(selectors.items, baseElement);
        if(!isMobile){
            const blazeSlider = new BlazeSlider(baseElement, {
                all: {
                    slidesToScroll: 1,
                    slidesToShow: 1.12,
                    slideGap: 'calc(38rem/16)',
                    draggable: true,
                    transitionDuration: 250,
                    transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                    loop: true
                },
            });
        }else{
            const blazeSlider = new BlazeSlider(baseElement, {
                all: {
                    slidesToScroll: 1,
                    slidesToShow: 1.2,
                    slideGap: 'calc(12rem/16)',
                    draggable: true,
                    transitionDuration: 250,
                    transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                    loop: true
                },
            });
        }
    });
}

