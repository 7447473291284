import {onFind} from "@elements/init-modules-in-scope";
import {on} from '@elements/dom-utils';
import CircleType from 'circletype';

const selectors = {
    base: '.js-rotate-circle',
};
export function init() {
    onFind(selectors.base, function (baseElement) {
        const rotate = new CircleType(baseElement, (string) => (string + ' ').split(''));
        on('scroll', function(){
            baseElement.style.transform=`rotate(${window.scrollY * 0.15}deg)`
        }, window);
    });
}

