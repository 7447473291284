"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {removeClass,on,addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-image-content-slider',
    items: '.js-image-content-slider__item'
};
const isMobile = matchMedia('(max-width: 767px)').matches || false;
console.log('image sliderr contennnttt');
export function init() {
    console.log('sliderr inittt')
    onFind(selectors.base, function (baseElement) {
        let onSlide = false;
        let blazeItems = findAllIn(selectors.items, baseElement);
        let allSlides = findAllIn('.js-image-content-slider__item', baseElement);
        addClass('is-active', allSlides[0]);
        const blazeSlider = new BlazeSlider(baseElement, {
            all: {
                slidesToScroll: 1,
                slidesToShow: 1.07,
                slideGap: 'calc(38rem/16)',
                draggable: false,
                transitionDuration: 250,
                transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                loop: false
            },
            '(max-width: 767px)': {
                slidesToScroll: 1,
                slidesToShow: 1.1,
                slideGap: 'calc(12rem/16)',
                draggable: true,
                transitionDuration: 250,
                transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                loop: false
            },
        });

        const unsubscribe = blazeSlider.onSlide(
            (pageIndex, firstVisibleSlideIndex, lastVisibleSlideIndex) => {
                let allSlides = findAllIn('.js-image-content-slider__item', baseElement);
                allSlides.forEach(function (slide) {
                    removeClass('is-active', slide);
                });

                if(pageIndex === allSlides.length - 1 ){
                    addClass('is-active', allSlides[allSlides.length - 1]);
                }else{
                    removeClass('is-active', allSlides[pageIndex]);
                    addClass('is-active', allSlides[firstVisibleSlideIndex]);
                }

            });

    });
}

