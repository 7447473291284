import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {removeClass,on,addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-card-teaser-slider',
    items: '.js-card-teaser-slider__item'
};
export function init() {
    onFind(selectors.base, function (baseElement) {
        let onSlide = false;
        let blazeItems = findAllIn(selectors.items, baseElement);
        let allSlides = findAllIn('.js-card-teaser-slider__item', baseElement);
        addClass('is-active', allSlides[1]);
        const blazeSlider = new BlazeSlider(baseElement, {
            all: {
                slidesToScroll: 1,
                slidesToShow: 3,
                slideGap: 'calc(38rem/16)',
                draggable: true,
                transitionDuration: 250,
                transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
                loop: false
            },
            '(max-width: 767px)': {
                slidesToShow: 1.3,
                slideGap: 'calc(12rem/16)',
            },
        });
    });
}

