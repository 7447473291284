import {add} from '@elements/scroll-animations';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-font-color-animation', function (baseElement) {
        let fontColorAnimations = [];
        fontColorAnimations.push(baseElement);
        add(
            fontColorAnimations,
            calculateAnimationProgress,
            setAnimationProgress
        );
    });
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    // animation for hero
    const start = (element.offsetHeight - 400) * (-1);
    const end = 400;

    return {
        progress : 1 - Math.max(
            Math.min(
                (element.getBoundingClientRect().top - end) / (start - end),
                1
            ),
            0
        )

    }

}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    const percentageAtProgress0 = -20;
    const percentageAtProgress1 = 100;

    const percentageAtProgress0_2 = 0;
    const percentageAtProgress1_2 = 120;

    // Calculate the interpolated percentage
    const percentage = (-20) + (100 - (-20)) * progress.progress + "%";
    const percentage2 = 120 * progress.progress + "%";



    //element.style.opacity = progress.progress;
    element.style.clipPath = "polygon(" + percentage2 + " 0, 100% 0, 100% 100%, " + percentage + " 100%)";
}
