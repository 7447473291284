import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass, removeClass, findAllIn, on, hasClass, trigger} from '@elements/dom-utils';

const selectors = {
    base: '.js-scroll-slide',
    img: '.js-scroll-slide__img',
    imgWrapper: '.js-scroll-slide__img-wrapper',
    content: '.js-scroll-slide__content',
    tabsWrapper: '.scroll-slide__tabs-wrapper',
    tab: '.js-scroll-slide__tab'
};

export function init () {
    onFind(selectors.base, function (baseElement) {

        const isMobile = () => matchMedia('(max-width: 767px)').matches;
        let images = findAllIn(selectors.img, baseElement);
        let imageWrapper = findIn(selectors.imgWrapper, baseElement);
        let tabsWrapper = findIn(selectors.tabsWrapper, baseElement);
        let contents = findAllIn(selectors.content, baseElement);
        let tabs = findAllIn(selectors.tab, baseElement);
        images.forEach(function (image, index) {
           let contentRec = image.getBoundingClientRect();
        });

        tabs.map(function (tab, index) {
            on('click', function (evt) {

                let img = images[index];
                let imgHeight = img.getBoundingClientRect().height;
                const isMobile = () => matchMedia('(max-width: 767px)').matches;

                let offset = isMobile() ? 49 + imgHeight : 129;
                let contentTop = contents[index].getBoundingClientRect().top;
                contents[index].scrollIntoView();
                window.scrollTo({
                    behavior: 'smooth',
                    top:
                        contents[index].getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top -
                        offset,
                })
            }, tab)
        });

        showActiveArea(imageWrapper, images, tabs, tabsWrapper, contents, isMobile);

        on('scroll', function () {
            showActiveArea(imageWrapper, images, tabs, tabsWrapper, contents, isMobile);
        }, document);
    });

    trigger('resize', window);
}

export function showActiveArea(imageWrapper, images, tabs, tabsWrapper, contents, isMobile) {
    let imgWrapperRec = imageWrapper.getBoundingClientRect();
    let contentFirstRec = contents[0].getBoundingClientRect();
    contents.forEach(function (content, index) {
        let contentRec = content.getBoundingClientRect();



        if(!isMobile()) {
            if(imgWrapperRec.top  >= contentRec.top && imgWrapperRec.top  < contentRec.bottom) {
                if(!hasClass('is-active', tabs[index])) {
                    addClass('is-active', images[index]);
                    addClass('is-active', tabs[index]);
                }
            }else{
                if(hasClass('is-active', tabs[index])) {
                    removeClass('is-active', images[index]);
                    removeClass('is-active', tabs[index]);
                }
            }
        }else{
            if(imgWrapperRec.bottom  <=  contentFirstRec.top){
                addClass('is-active', images[0]);
                addClass('is-active', tabs[0]);
            }
            else if(imgWrapperRec.bottom  >  contentRec.top && imgWrapperRec.bottom  < contentRec.bottom) {
                if(!hasClass('is-active', tabs[index])) {
                    addClass('is-active', images[index]);
                    addClass('is-active', tabs[index]);
                    let offsetLeft = tabs[index].getBoundingClientRect().left;
                    tabsWrapper.scrollTo({
                        left: offsetLeft - 80,
                        behavior: "smooth"
                    });
                }
            }else{
                if(hasClass('is-active', tabs[index])) {
                    removeClass('is-active', images[index]);
                    removeClass('is-active', tabs[index]);
                }
            }
        }
    });
}
