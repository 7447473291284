import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from '@elements/viewport-utils';
import {addClass, removeClass, findIn} from '@elements/dom-utils';

let settings = {
    offset: -100
};

export function init() {
    onFind('.js-counter-animation', function (baseElement) {
        onEnterViewPort (baseElement, function(element){

            let numbers = findIn('.js-counter-animation__number', baseElement);
            addClass('numbers-animation', numbers);
        }, settings);
    });
}
