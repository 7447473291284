import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as imgContentSlider from './scripts/img-content-slider';
imgContentSlider.init();

import * as imageContentSlider from './scripts/image-content-slider';
imageContentSlider.init();

import * as imgTeaserSlider from './scripts/img-teaser-slider';
imgTeaserSlider.init();

import * as imageSlider from './scripts/image-slider';
imageSlider.init();

import * as affix from "@elements/affix";
affix.init();

import * as autoplayVideo from './scripts/autoplay-video';
autoplayVideo.init();

import * as rotateCircle from './scripts/rotate-circle';
rotateCircle.init();

import * as scrollSlide from './scripts/scroll-slide';
scrollSlide.init();

import * as counterAnimation from './scripts/counter-animation';
counterAnimation.init();

import * as circleAnimation from './scripts/circle-animation';
circleAnimation.init();

import * as smoothScroll from '@elements/smooth-scroll';

let selectors = {
    base: '.js-smoothscroll',
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
}

let options = {
    scrollOffset: 0,
}
smoothScroll.init();


import * as cardTeaserSlider from './scripts/card-teaser-slider';
cardTeaserSlider.init();

import * as fontColorAnimation from './scripts/font-color-animation';
fontColorAnimation.init();


import * as parallax from "./scripts/parallax";
parallax.init();


import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger: { event: 'false'}
});


import * as playVideo from './scripts/play-video';
playVideo.init();

import * as datepickerRange from "@elements/datepicker-range";
datepickerRange.init();

